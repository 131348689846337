import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/enviroment';
import { IWalletDetails, IWalletsResponse } from '../interfaces/wallets';
import { IWalletTopUp } from '../../orders/interfaces/topUp';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  public currencies = [
    { currency: 'USD' },
    { currency: 'CAD' },
    { currency: 'EUR' },
    { currency: 'AED' },
    { currency: 'AFN' },
    { currency: 'ALL' },
    { currency: 'AMD' },
    { currency: 'ARS' },
    { currency: 'AUD' },
    { currency: 'AZN' },
    { currency: 'BAM' },
    { currency: 'BDT' },
    { currency: 'BGN' },
    { currency: 'BHD' },
    { currency: 'BIF' },
    { currency: 'BND' },
    { currency: 'BOB' },
    { currency: 'BRL' },
    { currency: 'BWP' },
    { currency: 'BYN' },
    { currency: 'BZD' },
    { currency: 'CDF' },
    { currency: 'CHF' },
    { currency: 'CLP' },
    { currency: 'CNY' },
    { currency: 'COP' },
    { currency: 'CRC' },
    { currency: 'CVE' },
    { currency: 'CZK' },
    { currency: 'DJF' },
    { currency: 'DKK' },
    { currency: 'DOP' },
    { currency: 'DZD' },
    { currency: 'EEK' },
    { currency: 'EGP' },
    { currency: 'ERN' },
    { currency: 'ETB' },
    { currency: 'GBP' },
    { currency: 'GEL' },
    { currency: 'GHS' },
    { currency: 'GNF' },
    { currency: 'GTQ' },
    { currency: 'HKD' },
    { currency: 'HNL' },
    { currency: 'HRK' },
    { currency: 'HUF' },
    { currency: 'IDR' },
    { currency: 'ILS' },
    { currency: 'INR' },
    { currency: 'IQD' },
    { currency: 'IRR' },
    { currency: 'ISK' },
    { currency: 'JMD' },
    { currency: 'JOD' },
    { currency: 'JPY' },
    { currency: 'KES' },
    { currency: 'KHR' },
    { currency: 'KMF' },
    { currency: 'KRW' },
    { currency: 'KWD' },
    { currency: 'KZT' },
    { currency: 'LBP' },
    { currency: 'LKR' },
    { currency: 'LTL' },
    { currency: 'LVL' },
    { currency: 'LYD' },
    { currency: 'MAD' },
    { currency: 'MDL' },
    { currency: 'MGA' },
    { currency: 'MKD' },
    { currency: 'MMK' },
    { currency: 'MOP' },
    { currency: 'MUR' },
    { currency: 'MXN' },
    { currency: 'MYR' },
    { currency: 'MZN' },
    { currency: 'NAD' },
    { currency: 'NGN' },
    { currency: 'NIO' },
    { currency: 'NOK' },
    { currency: 'NPR' },
    { currency: 'NZD' },
    { currency: 'OMR' },
    { currency: 'PAB' },
    { currency: 'PEN' },
    { currency: 'PHP' },
    { currency: 'PKR' },
    { currency: 'PLN' },
    { currency: 'PYG' },
    { currency: 'QAR' },
    { currency: 'RON' },
    { currency: 'RSD' },
    { currency: 'RUB' },
    { currency: 'RWF' },
    { currency: 'SAR' },
    { currency: 'SDG' },
    { currency: 'SEK' },
    { currency: 'SGD' },
    { currency: 'SOS' },
    { currency: 'SYP' },
    { currency: 'THB' },
    { currency: 'TND' },
    { currency: 'TOP' },
    { currency: 'TRY' },
    { currency: 'TTD' },
    { currency: 'TWD' },
    { currency: 'TZS' },
    { currency: 'UAH' },
    { currency: 'UGX' },
    { currency: 'UYU' },
    { currency: 'UZS' },
    { currency: 'VEF' },
    { currency: 'VND' },
    { currency: 'XAF' },
    { currency: 'XOF' },
    { currency: 'YER' },
    { currency: 'ZAR' },
    { currency: 'ZMK' },
    { currency: 'ZWL' },
  ];
  public userToken = sessionStorage.getItem('adminUserToken');

  private _HttpClient = inject(HttpClient);

  getWallets(
    page: number,
    limit: number,
    username?: string,
    email?: string,
    currency?: string
  ): Observable<IWalletsResponse> {
    let optionalParams = [];
    if (username) optionalParams.push(`&username=${username}`);
    if (email) optionalParams.push(`&email=${email}`);
    if (currency) optionalParams.push(`&currency=${currency}`);

    return this._HttpClient.get<IWalletsResponse>(
      `${
        environment.apiUrl
      }/v1/wallet/admin-wallets-list?page=${page}&limit=${limit}${optionalParams
        .join('')
        .split(',')
        .toString()}`
    );
  }

  getWalletsDetails(id: string): Observable<IWalletDetails> {
    return this._HttpClient.get<IWalletDetails>(
      `${environment.apiUrl}/v1/wallet/admin-wallet-details/${id}`
    );
  }

  getWalletLogs(): Observable<any> {
    return this._HttpClient.get(environment.apiUrl);
  }

  sendWalletTopUp(id: string, amount: string): Observable<IWalletTopUp> {
    return this._HttpClient.patch<IWalletTopUp>(
      `${environment.apiUrl}/v1/wallet/admin-topup-wallet/${id}/${amount}`,
      {}
    );
  }
}
